<template>
	<div class="app-layer" :class="{ 'is-active': restApiActive }">
		<TheRestApiDetails v-if="restApiActive" />
	</div>
	<div
		class="overlay-layer app-layer"
		:class="{ 'is-active': !restApiActive }"
	>
		<NewRestApi v-if="showCreateNew" />

        <Suspense>
			<TheRestApiOverview v-show="showOverview" />
        </Suspense>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStoreAction, getStoreGetter } from '@assets/scripts/store/config';
import usePermission from '@assets/scripts/composables/usePermission';

import NewRestApi from '@modules/RestApiBuilder/materials/structures/NewRestApi.vue';
import TheRestApiOverview from '@modules/RestApiBuilder/materials/structures/TheRestApiOverview.vue';
import TheRestApiDetails from '@modules/RestApiBuilder/materials/structures/TheRestApiDetails.vue';

export default {
	name: 'TheRestApiPage',
	components: {
        TheRestApiOverview,
        TheRestApiDetails,
        NewRestApi,
	},
	data: function () {
		return {
			overlayIsActive: true,
		};
	},
    computed: {
		...mapGetters({
			/**
			 * Boolean to indicate whether the form to
			 * create a new REST API should be displayed
			 */
			showCreateNew: getStoreGetter('SHOW_CREATE_NEW', 'RA'),
			/**
			 * Boolean to indicate whether a REST API is
			 * currently active
			 */
			restApiActive: getStoreGetter('REST_API_ACTIVE', 'RA'),
		}),
		/**
		 * Boolean to indicate whether the current
		 * user can manage API users
		 */
		canReadRestApi: function () {
			return usePermission('Read', 'RestApiBuilder');
		},
		showOverview: function () {
			return !this.showCreateNew && !this.restApiActive && this.canReadRestApi
		}
	},
	unmounted: function () {
		// reset store to default state when page is unmounted
		this.$store.dispatch(getStoreAction('RESET', 'RA'));
	},
};
</script>

