<template>
	<div class="content-header has-bottom-divider has-padding-top-3 has-margin">
		<div class="field is-grouped is-flex-grow-1 is-align-items-end">
			<VLink
				v-if="canEditRestApi"
				:text="$t('ra.restApiList.addRestApi')"
				icon="plus"
				:hasUnderline="true"
				@click.prevent.stop="createNewClicked"
				class="has-margin-right-3 has-margin-bottom-05"
			/>

			<VSearch v-model="search" />
		</div>
	</div>

	<div class="scrollable-content">
		<SortableDataTable
			:rowClickedFn="rowClicked"
			:scrollable="false"
			:data="filteredData"
			defaultSort="name"
			:columns="columns"
			:emptyText="$t('ra.restApiList.table.noResults')"
		/>
	</div>
</template>

<script>
import { useStore } from 'vuex';
import { reactive, toRefs } from '@vue/reactivity';
import { getStoreAction, getStoreMutation } from '@assets/scripts/store/config';
import { formatForTable } from '@modules/RestApiBuilder/components/restapi';
import Helpers from '@assets/scripts/helpers';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import usePermission from '@assets/scripts/composables/usePermission';
import { GET_REST_API_LIST } from '@modules/RestApiBuilder/endpoints';

export default {
	name: 'RestApiList',
	data: function () {
		return {
			search: '',
		};
	},
	async setup () {
		const store = useStore();

		const state = reactive({
			restApis: [],
		});

		const loadRestApis = async () => {
			// get all REST API's
			state.restApis = await useApiAsync(GET_REST_API_LIST);
		};

		// subscribe to store action
		const unsubscribeAction = store.subscribeAction({
			after: async ({ type }) => {
				// check action type
				if (type === getStoreAction('UPDATE_REST_API_LIST', 'RA')) {
					// reload REST API list if needed
					await loadRestApis();
				}
			}
		});

		await loadRestApis();

		return { ...toRefs(state), unsubscribeAction };
	},
	computed: {
		/**
		 * Boolean to indicate whether the current
		 * user can manage API users
		 */
		canEditRestApi: function () {
			return usePermission('Upsert', 'RestApiBuilder');
		},
		columns: function() {
			return [
				{
					label: this.$t('ra.restApiList.table.columns.name'),
					field: 'name',
					sortable: true,
					searchable: true,
					cellClass: 'has-text-weight-semibold',
				},
                {
					label: this.$t('ra.restApiList.table.columns.returnType'),
					field: 'returnType',
					sortable: true,
					searchable: true,
				},
				{
					label: this.$t('ra.restApiList.table.columns.isActive'),
					field: 'isActive',
					sortable: true,
					searchable: true,
				},
				{
					label: this.$t('ra.restApiList.table.columns.last_time_edited'),
					field: 'last_time_edited',
					sortable: true,
					searchable: true,
					isDate: true,
				},
				{
					label: '',
					field: 'edit',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						edit: {
							title: this.$t('general.edit'),
							isTool: true,
							icon: 'edit',
						},
						view: {
							title: this.$t('general.inspect'),
							isTool: true,
							icon: 'eye',
						}
					},
					click: this.rowClicked,
				},
				{
					label: '',
					field: 'delete',
					sortable: false,
					default: '',
					component: 'VButton',
					cellClass: 'is-button-tool',
					args: {
						href: '',
						title: this.$t('general.delete'),
						isTool: true,
						class: '',
						icon: 'delete',
					},
					click: this.deleteClicked,
				},
			];
		},
		tableFields: function () {
			return formatForTable(this.restApis);
		},
		searchableCols: function () {
			return Helpers.getSearchableColumns(this.columns);
		},
		filteredData: function () {
			// filter on search string
			return Helpers.filterByString(
				this.tableFields,
				this.search,
				this.searchableCols
			);
		},
	},
	methods: {
		rowClicked({ guid }) {
			// open drawer with REST API information
			this.$store.dispatch(
				getStoreAction('LOAD_AND_SHOW_REST_API', 'RA'),
				guid
			);
		},
		deleteClicked({ guid }) {
			// ask confirmation to delete REST API
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('ra.restApiList.delete.confirm.title'),
				body: this.$t('ra.restApiList.delete.confirm.body'),
				confirmButtonText: this.$t(
					'ra.restApiList.delete.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// dispatch action to delete clicked REST API
					this.$store.dispatch(
						getStoreAction('DELETE_REST_API', 'RA'),
						guid
					);
				},
			});
		},
		createNewClicked: function () {
			// create new REST API
			this.$store.dispatch(
				getStoreAction('TOGGLE_CREATE_NEW', 'RA'),
				true
			);
		},
	},
	unmounted: function () {
		// unsubscribe from actions
		this.unsubscribeAction();
	},
};
</script>
