<template>
	<div class="page-with-options-wrapper">
		<TheAppOptions
			:backButtonText="$t('ra.restApiDetails.backToOverview')"
			:backClickedFn="closeRestApi"
			:nameLabel="$t('ra.restApiDetails.restApiName')"
			v-model:name="restApiName"
			@update:name="updateName"
			:saveClickedFn="saveRestApi"
			:editMode="restApi.is_new || usePermission('Upsert name', 'RestApiBuilder')"
			:showButtons="showButtons"
			:canBeSaved="false"
			:canBePublished="canBePublished && modified"
			:publishClickedFn="publishRestApi"
			:canBeValidated="canBeValidated"
			:validateClickedFn="validateRestApi"
			:validationErrorListItemIsClickable="false"
			:trimName="true"
		/>

		<div class="page-content js-page-content">
			<ContentBlock
				:size="3"
				:title="$t('ra.restApiDetails.restApiSettings')"
				:hasBorderUnderTitle="true"
			>
                <VField
                    :label="$t('ra.restApiDetails.selectReturnType')"
                    :isFloating="true"
                    :class="{ 'is-active': restApi.return_type }"
                >
                    <VSelect :disabled="!canBeEdited" :options="returnTypeOptions" v-model="restApi.return_type" @change="markRestApiAsUpdated"/>
                </VField>
                <VField
                    :label="$t('ra.restApiDetails.selectApiAuth')"
                    :isFloating="true"
                    :class="{ 'is-active': restApi.api_authentication }"
                >
                    <VSelect :disabled="!canBeEdited" :options="apiAuthOptions" v-model="restApi.api_authentication" @change="markRestApiAsUpdated"/>
                </VField>
				<VField class="is-fullwidth">
					<VOption
						type="checkbox"
						:label="$t('ra.restApiDetails.isActive')"
						wrapperClasses="has-margin-0 has-margin-right-05"
						:disabled="!canBeEdited"
						@change="markRestApiAsUpdated"
						v-model:modelValue="restApi.is_active"
					/>
				</VField>
				<VField v-if="canMakeInternal" class="is-fullwidth">
					<VOption
						type="checkbox"
						:label="$t('ra.restApiDetails.isInternal')"
						wrapperClasses="has-margin-0 has-margin-right-05"
						:disabled="!canBeEdited"
						@change="markRestApiAsUpdated"
						v-model:modelValue="restApi.is_internal"
					/>
				</VField>
				<div class="has-padding-top-1">
					<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('ra.restApiDetails.description')" />

					<VTextarea
						v-model="restApi.description"
						:placeholder="$t('ra.restApiDetails.description')"
						class="has-margin-bottom-4"
						:isNarrow="true"
						@change="markRestApiAsUpdated"
						:disabled="!canBeEdited"
					/>
				</div>
			</ContentBlock>
			<ContentBlock
				:size="3"
				:title="$t('ra.restApiDetails.users')"
				:hasBorderUnderTitle="true"
			>
                <div class="has-padding-top-1">
                    <template v-for="(user, id) in users" :key="id">
                        <VField class="is-fullwidth">
                            <VOption
                                type="checkbox"
                                :label="user.username"
                                v-model:modelValue="userModel[user.guid]"
                                wrapperClasses="has-margin-0 has-margin-right-05"
                                :disabled="!canBeEdited"
                                @update:modelValue="modelChanged"
                            />
                        </VField>
                    </template>
                </div>
			</ContentBlock>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';
import {
	getStoreGetter,
	getStoreMutation,
	getStoreAction,
} from '@assets/scripts/store/config';
import { useApiAsync } from '@assets/scripts/composables/useApi';
import usePermission from '@assets/scripts/composables/usePermission';
import {
	GET_API_USERS,
	GET_API_RETURN_TYPES,
	GET_API_AUTHENTICATIONS
} from '@modules/RestApiBuilder/endpoints';

import TheAppOptions from '@materials/structures/TheAppOptions.vue';

export default {
	name: 'TheMetaDocumentDetails',
	components: {
		TheAppOptions,
	},
	data: function () {
		return {
			restApiName: '',
			search: '',
            users: [],
			userModel: {},
            returnTypeOptions: [],
            apiAuthOptions: [],
			usePermission: usePermission,
		};
	},
	mounted: async function () {
        // clear validation when REST API is loded
		this.$store.dispatch(getStoreAction('CLEAR_VALIDATION'));

		this.restApiName =
			this.restApi && this.restApi.name
				? this.restApi.name
				: '';
        
        this.users = await useApiAsync(GET_API_USERS);

        this.users.forEach((user) => {
			// create user model for current REST API
			this.userModel[user.guid] =
				this.restApi.users.indexOf(user.guid) > -1;
		});

        // get all available return types objects
		const returnTypes = await useApiAsync(GET_API_RETURN_TYPES);

		// format return types to use in VSelect component
		if (returnTypes.length > 0) {
			this.returnTypeOptions = returnTypes.map((type) => {
				return {
					value: type,
					text: type,
				};
			});
		}

        // get all available api authentication objects
		const apiAuthentications = await useApiAsync(GET_API_AUTHENTICATIONS);

		// format return types to use in VSelect component
		if (apiAuthentications.length > 0) {
			this.apiAuthOptions = apiAuthentications.map((type) => {
				return {
					value: type,
					text: type,
				};
			});
		}
	},
	computed: {
		...mapGetters({
			/**
			 * Gets currently active REST API
			 */
			restApi: getStoreGetter('CURRENT_REST_API', 'RA'),
			/**
			 * Boolean to indicate whether user modified the REST API
			 */
			modified: getStoreGetter('REST_API_MODIFIED', 'RA'),
		}),
		/**
		 * Boolean to indicate whether current REST API
		 * can be edited
		 */
		canBeEdited: function () {
			return usePermission('Upsert', 'RestApiBuilder');
		},
		canMakeInternal: function () {
			return usePermission('Make internal', 'RestApiBuilder');
		},
		/**
		 * Boolean to indicate whether current REST API
		 * can be published
		 */
		canBePublished: function () {
			return usePermission('Upsert', 'RestApiBuilder');
		},
		showButtons: function () {
			return this.canBeEdited;
		},
		canBeValidated: function () {
			return this.canBeEdited || this.canBePublished;
		},
	},
	methods: {
        modelChanged: function () {
			// loop over user model
			for (const user in this.userModel) {
				if (
					this.userModel[user] &&
					this.restApi.users.indexOf(user) < 0
				) {
					// add user to REST API
					this.restApi.users.push(user);
				} else if (
					!this.userModel[user] &&
					this.restApi.users.indexOf(user) > -1
				) {
					// remove user from REST API
					const index = this.restApi.users.indexOf(user);
					this.restApi.users.splice(index, 1);
				}
			}
			this.markRestApiAsUpdated();
		},
		markRestApiAsUpdated: function () {
			// mark REST API as modified whenever a
			// change is made
			this.$store.commit(getStoreMutation('MARK_REST_API_MODIFIED', 'RA'));
		},
		closeRestApi: function () {
			const closeOverlay = () => {
				// dispatch action to unset REST API
				this.$store.dispatch(getStoreAction('UNSET_REST_API', 'RA'));
			};

			if (!this.modified) {
				// close immediately if config has not been modified
				closeOverlay();
			} else {
				// ask confirmation before closing if a change has been made
				// to the REST API
				this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
					title: this.$t('ra.restApiDetails.close.confirm.title'),
					body: this.$t('ra.restApiDetails.close.confirm.body'),
					confirmButtonText: this.$t(
						'ra.restApiDetails.close.confirm.confirmButtonText'
					),
					confirmFn: () => {
						// close after confirmation
						closeOverlay();
					},
				});
			}
		},
		validateRestApi: function () {
			// dispatch action to Validate REST API
			this.$store.dispatch(
				getStoreAction('VALIDATE_CURRENT_REST_API', 'RA')
			);
		},
		saveRestApi: function () {
			this.$store.dispatch(
				getStoreAction('SAVE_CURRENT_REST_API', 'RA')
			);
		},
		publishRestApi: function () {
			// ask confirmation before publishing
			this.$store.commit(getStoreMutation('OPEN_CONFIRMATION'), {
				title: this.$t('ra.restApiDetails.publish.confirm.title'),
				body: this.$t('ra.restApiDetails.publish.confirm.body'),
				confirmButtonText: this.$t(
					'ra.restApiDetails.publish.confirm.confirmButtonText'
				),
				confirmFn: () => {
					// dispatch action to publish REST API
					this.$store.dispatch(
						getStoreAction('PUBLISH_CURRENT_REST_API', 'RA')
					);
				},
			});
		},
		updateName: function (newName) {
			// commit mutation in store
			this.$store.commit(getStoreMutation('SET_NAME', 'RA'), newName);
		},
	},
};
</script>