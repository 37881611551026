<template>
	<VOverlay
		:title="$t('ra.newRestApi.title')"
		:size="2"
		:isVCentered="false"
		:closeOnBackgroundClick="true"
		:hasBackground="true"
		:modelValue="true"
		@update:modelValue="overlayClosed"
		:modalHasSharpCorner="true"
		@keydown.meta.s.prevent.stop="submit"
	>
		<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('ra.newRestApi.whatName')" />

		<VInputString
			:isRequired="true"
			v-model:valueModel="restApiNameValue"
			:label="$t('ra.newRestApi.nameInputLabel')"
			:trim="true"
			@update:valueModel="markModified"
		/>

		<VTitle classes="has-margin-bottom-0" :size="4" :text="$t('ra.newRestApi.giveDescription')" />

		<VTextarea
			v-model="restApiDescription"
			:placeholder="$t('ra.newRestApi.giveDescription')"
			class="has-margin-bottom-4"
			:isNarrow="true"
		/>

		<template #footer>
			<VButton
				:text="$t('ra.newRestApi.submitButton')"
				icon="chevron-right"
				:iconOnRight="true"
				classes="button-modal-footer"
				:isDisabled="!isValid"
				@clicked="submit"
			/>
		</template>
	</VOverlay>
</template>

<script>
import { mapGetters } from 'vuex';
import { getStoreAction, getStoreGetter, getStoreMutation } from '@assets/scripts/store/config';

export default {
	name: 'NewRestApi',
	data: function () {
		return {
			restApiNameValue: '',
			restApiDescription: '',
		};
	},
	computed: {
		...mapGetters({
			modified: getStoreGetter('REST_API_MODIFIED', 'RA'),
		}),
		isValid: function () {
			return this.restApiNameValue.trim();
		},
	},
	methods: {
		markModified: function () {
			// no need to trigger this mutation more than once
			if (this.modified) return;

			// mark REST API as modified whenever a
			// change is made
			this.$store.commit(
				getStoreMutation('MARK_REST_API_MODIFIED', 'RA')
			);
		},
		overlayClosed: function () {
			this.$store.dispatch(
				getStoreAction('TOGGLE_CREATE_NEW', 'RA'),
				false
			);
		},
		submit: function () {
			if (!this.isValid) return;

			this.$store.dispatch(getStoreAction('NEW_REST_API', 'RA'), {
				name: this.restApiNameValue.trim(),
				description: this.restApiDescription.trim(),
			});
		},
	},
};
</script>
