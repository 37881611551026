<template>
    <VOverlay
        :hasBackground="false"
        :hasJsonButton="false"
        :hasCloseButton="false"
        :closeOnBackgroundClick="false"
        :isLarge="true"
        :isVCentered="false"
        :hasFullwidthBody="true"
        :hasScrollableContent="true"
        v-model="overlayIsActive"
        :title="$t('ra.restApiManagement')"
        :useFocusTrap="false"
        :closeWithEscape="false"
    >
        <VTabs>
            <VTabItem :title="$t('ra.restApi')">
                <Suspense>
                    <RestApiList />
                </Suspense>
            </VTabItem>
            <VTabItem :title="$t('ra.apiUsers')">
                <Suspense>
                    <ApiUserList />
                </Suspense>
            </VTabItem>
        </VTabs>
    </VOverlay>
</template>

<script>
import RestApiList from '@modules/RestApiBuilder/materials/structures/RestApiList.vue';
import ApiUserList from '@modules/RestApiBuilder/materials/structures/ApiUserList.vue';

export default {
	name: 'TheRestApiOverview',
	components: {
		RestApiList,
		ApiUserList,
	},
	data: function () {
		return {
			overlayIsActive: true,
		};
	},
};
</script>